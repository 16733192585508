import { Component, OnInit } from '@angular/core';
import {UsersService} from "../../services/users.service";

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss']
})
export class StatisticsComponent implements OnInit {
  selectedDate = null;
  availableDates = []
  updateDate = null;
  public  ep = { step1: 0, step2:0, step3: 0, finish: 0,becoming:0, total: 0};
  public  bp = { step1: 0, step2:0, step3: 0, finish: 0,becoming:0, total: 0};
  constructor(private usersService: UsersService) {
    let now = new Date();
    for (let d = 2020; d <= now.getFullYear()+1; d++) {
      this.availableDates.push(d);
    }
    this.selectedDate = now.getFullYear().toString();
  }

  ngOnInit(): void {
    this.updateDate = new Date();
    this.usersService.getStatistics().subscribe(res => {
      this.ep = res.ep;
      this.bp = res.bp;
    })
  }

  changeDate($event) {
    this.selectedDate = $event.value;
    this.usersService.getStatistics(this.selectedDate).subscribe(res => {
      this.ep = res.ep;
      this.bp = res.bp;
    })
  }

  parseDate(date: string) {
    return Date.parse(date);
  }

}
