<div class="popin-wrapper">
  <div mat-dialog-title>
    <div>
      <span>Liste</span>
      <span>des champs</span>
    </div>

    <mat-icon class="mdi mdi-32px mdi-close" [mat-dialog-close]="false"></mat-icon>
  </div>

  <div mat-dialog-content>
    <div>
      <span class="field" *ngFor="let field of fields">{{field}}</span>
    </div>
  </div>

</div>


