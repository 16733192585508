import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";

@Component({
  templateUrl: './field-list.component.html',
  styleUrls: ['./field-list.component.scss']
})
export class FieldListComponent implements OnInit {

  public fieldsHistory = [
    "Matricule","Nom","Prénom","Date Naissance","C Société St. Jur.","L Société St. Jur.","D Ancienneté (fictive)","L Catégorie Bilan social résolue","L nature contrat","L Type Contrat","Date d'entrée dans l'emploi","Date de sortie dans l'emploi","L Emploi","E-mail professionnel"
  ]

  public fieldsFormations = [
    "Matricule","Nom","Prénom","Société","Brand 1","Analytique","Fonction","Date de naissance","Age","Ancienneté","Date d'entrée","Sexe","Catégorie","Niveau","Type de contrat","Intitulé du stage","Catégorie","Date de début","Date de fin","Organisme","Interne/externe","Individuel/Collectif","Nb participants Formation Interne","catégorie d'action","Participation","Nb heures effectuées PTT","Nb heures effectuées HTT","Si certification, titre","Obtention certification"
  ]

  public fields: string[] = []

  constructor( @Inject(MAT_DIALOG_DATA) public data: any) {
    if(data.dialogType === 'history') {
      this.fields = this.fieldsHistory;
    }
    else {
      this.fields = this.fieldsFormations;
    }
  }

  ngOnInit(): void {
  }

}
