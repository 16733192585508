import { Component, OnInit } from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {FieldListComponent} from "./field-list/field-list.component";

@Component({
  selector: 'app-synchro-bdd',
  templateUrl: './synchro-bdd.component.html',
  styleUrls: ['./synchro-bdd.component.scss']
})
export class SynchroBddComponent implements OnInit {

  constructor(private dialog: MatDialog) { }

  ngOnInit(): void {
  }

  public openFieldList(type: string): void {
    this.dialog.open(FieldListComponent, {data : {
        dialogType: type
      }});
  }

}
