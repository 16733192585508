import { Component, OnInit } from '@angular/core';
import {AuthenticationService} from "../../services/authentication.service";

@Component({
  selector: 'app-switch-user',
  templateUrl: './switch-user.component.html',
  styleUrls: ['./switch-user.component.scss']
})
export class SwitchUserComponent implements OnInit {
  idUser = null;
  constructor(private authenticationService: AuthenticationService) { }

  ngOnInit(): void {
  }

  changeUser() {
    this.authenticationService.switchUser(this.idUser).subscribe(res => {

    })
  }

  reset() {
    this.authenticationService.reset().subscribe(res => {})
  }

}
