import {MatDatepickerIntl} from "@angular/material/datepicker";
import {Injectable} from "@angular/core";

import * as moment from 'moment';
import './moment.fr';

@Injectable({ providedIn: 'root' })
export class MatDatepickerIntlCroComponent extends MatDatepickerIntl {
  constructor() {
    super();
    moment.locale('fr');
  }

  /** A label for the calendar popup (used by screen readers). */
  calendarLabel = 'Calendrier';

  /** A label for the button used to open the calendar popup (used by screen readers). */
  openCalendarLabel = 'Calendrier ouvert';

  /** A label for the previous month button (used by screen readers). */
  prevMonthLabel = 'Le mois dernier';

  /** A label for the next month button (used by screen readers). */
  nextMonthLabel = 'Le mois suivant';

  /** A label for the previous year button (used by screen readers). */
  prevYearLabel = 'L\'année dernière';

  prevMultiYearLabel = 'La période pluriannuelle précédente';

  /** A label for the next year button (used by screen readers). */
  nextYearLabel = 'L\'année suivante';

  /** A label for the 'AM' button (used by screen readers). */
  setToAMLabel = 'Régler l\'heure sur le matin';

  /** A label for the 'PM' button (used by screen readers). */
  setToPMLabel = 'Régler l\'heure sur l\'après-midi';

  /** A label for the 'switch to minute view' button (used by screen readers). */
  switchToMinuteViewLabel = 'Passer à la vue par minute';

  /** A label for the 'switch to hour view' button (used by screen readers). */
  switchToHourViewLabel = 'Passer à la vue par heure';

  /** A label for the 'switch to month view' button (used by screen readers). */
  switchToMonthViewLabel = 'Passer à la vue mensuelle';

  switchToMultiYearViewLabel = 'Passer à la vue annuelle';

  /** A label for the 'switch to year view' button (used by screen readers). */
  switchToYearViewLabel = 'Passer à la vue par année';

  /** A label for the 'switch to years view' button (used by screen readers). */
  switchToYearsViewLabel = 'Passer à la vue des années';

  /** Text for the 'submit' button. */
  buttonSubmitText = 'Ok';

  /** A label for the 'submit' button (used by screen readers). */
  buttonSubmitLabel = 'Choisissez la date sélectionnée';

  /** Text for the 'cancel' button. */
  buttonCancelText = 'Annuler';

  /** A label for the 'cancel' button (used by screen readers). */
  buttonCancelLabel = 'Annuler la sélection de la date';
}
