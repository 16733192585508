<div class="middle-content" >
  <mat-form-field>
    <mat-select [(ngModel)]="idUser">
      <mat-option [value]="63024786">manager</mat-option>
      <mat-option [value]="63024788">RH</mat-option>
      <mat-option [value]="63024787">user</mat-option>
    </mat-select>
  </mat-form-field>

  <button mat-button color="primary" (click)="changeUser()">Changer d'utilisateur</button>
  <button mat-button color="primary" (click)="reset()">Reset</button>
</div>
