import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {BaseService} from './base-http.service';

@Injectable({
  providedIn: 'root'
})
export class SkillsService extends BaseService {

  constructor(
    private httpClient: HttpClient
  ) {
    super();
  }

  public get_categories(): Observable<any> {
    return this.httpClient.get<any>('/api/skills/get_categories');
  }

  public get_skills(category: string): Observable<any> {
    let httpParams = new HttpParams().set('category', category);
    return this.httpClient.get<any>('/api/skills/get_skills', {params: httpParams});
  }
}
