import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BackgroundsService {

  public backgroundsSource = new BehaviorSubject<string>('/');
  private backgrounds = [
    'faq',
    'home',
    'synchro-bdd',
    'space',
    'rh'
  ];

  public updateBackground(value: string): void {
    value = (value.indexOf('?') !== -1) ? value.substr(1, value.indexOf('?') - 1) : value.substr(1, value.length - 1);
    this.backgroundsSource.next(value === '' ? 'home' : this.backgrounds.includes(value) ? value : 'home');
  }

  public getBackground(): string {
    return this.backgroundsSource.value;
  }
}
