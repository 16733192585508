import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, RouterStateSnapshot} from '@angular/router';
import {AuthenticationService} from './authentication.service';
import {Observable, of, Subject} from 'rxjs';
import {catchError, map, tap} from 'rxjs/internal/operators';
import {BackgroundsService} from "./backgrounds.services";

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate, CanLoad {

  private loginPage = '/';
  private unauthorizedPage = '/unauthorized';

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private backgroundsService: BackgroundsService
  ) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    this.backgroundsService.updateBackground(state.url);
    return this.checkLogin(state.url, next.queryParams.access_token);
  }

  canLoad(route: Route): Observable<boolean> | boolean {
    return this.authenticationService.isLogged;

  }

  private checkLogin(url: string,  access_token : string): Observable<boolean> | boolean {
    if (this.authenticationService.isLogged) {
      return true;
    }
    if ( url !== this.loginPage) {
      this.authenticationService.lastUrl = url;
    }
    if (access_token)
      return this.authenticationService.checkLogin(access_token).pipe(tap(e => {
        if (!e)
          this.router.navigate([this.unauthorizedPage]);
        else
          this.router.navigate([url]);
      }));
      return this.authenticationService.checkToken().pipe(tap(e => {
        if (!e)
          this.authenticationService.authenticate();
      }))
  }
}
