import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {EPService} from "../../services/ep.service";
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {Observable} from "rxjs";
import {map, startWith} from "rxjs/operators";
import {BrandsService} from "../../services/brands.service";
import * as moment from 'moment';
import {SkillsService} from "../../services/skills.service";
import {MatTable} from "@angular/material/table";
import {MatSnackBar} from "@angular/material/snack-bar";
import {HttpParams} from "@angular/common/http";
import {UsersService} from "../../services/users.service";

@Component({
  selector: 'app-ep',
  templateUrl: './ep.component.html',
  styleUrls: ['./ep.component.scss']
})
export class EpComponent implements OnInit {

  public StatesValues = {
    NOT_STARTED: 1,
    SENT_TO_MANAGER: 2,
    SIGNED_BY_MANAGER: 3,
    TERMINATE: 4
  }

  public categoriesEvoPro = [
  'Expertise', 'Conseil' , 'Supports',
  'Management', 'Commercial', 'Autres'
]

  public epFormGroup: FormGroup;
  public hasTrainingData = false;
  public hasSkillsToDevelopData = false;
  public hasJobHistoryData = false;
  public ep: any;
  public isUser = false;
  public isManager = false;
  public step = 0;

  public today = null;
  public minDate = null;

  public expandAll = false;

  public state = this.StatesValues.NOT_STARTED;

  public efficiencyMatches: any = {
    1: 'Moyenne',
    2: 'Satisfaisante',
    3: 'Très Satisfaisante'
  };

  public priorityMatches: any = {
    1: 'Faible',
    2: 'Moyenne',
    3: 'Forte'
  };

  public date = '';

  public formationActionsHeaders = ['category', 'name', 'efficacity', 'comment', 'action'];

  public formationPlanHeader = ['category', 'skill', 'priority', 'action'];

  public professionalEvolutionHeader = ['position_held', 'brand', 'start_date', 'end_date', 'action'];

  public filteredBrands: Observable<string[]> = new Observable<string[]>();
  public brands: any = [];

  public filterCategoriesEvoPro: Observable<string[]> = new Observable<string[]>();
  public filterCategories: Observable<string[]> = new Observable<string[]>();
  public categories: any = [];

  public filterUsers: Observable<any[]> = new Observable<any[]>();
  public users: any = [];

  public filterSkills: Observable<string[]> = new Observable<string[]>();
  public skills: any = [];

  @ViewChild('trainingTable') trainingTable: MatTable<string>;
  @ViewChild('skillsToDevelopTable') skillsToDevelopTable: MatTable<string>;
  @ViewChild('jobHistoryTable') jobHistoryTable: MatTable<string>;

  constructor(
    private route: ActivatedRoute,
    private epService: EPService,
    private brandsService: BrandsService,
    private skillsService: SkillsService,
    private usersService: UsersService,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private router: Router
    ) {
    this.epFormGroup = this.formBuilder.group({
      epDate: [''],
      firstname: [''],
      lastname: [''],
      seniorityDate: [''],
      job: [''],
      legalEntity: [''],
      brand: [''],
      manager: [''],
      skillCategory: [''],
      skillName: [''],
      isPeriodicEP: [''],
      isSpecialEP: [''],
      categoryEvolutionPro: [''],
      activityEvolutionPro: [''],
      whenEvolutionPro: [''],
      mobility: [''],
      national: [''],
      international: [''],
      userComments: [''],
      managerComments: [''],
      userSign: [false],
      managerSign: [false]
    })

    this.today = moment().toISOString()
    this.minDate = moment().subtract(2,'year').toISOString()
    this.route.queryParams.subscribe(params => {
      if (params['year']) {
        this.date = params['year'];
        let _id = params['id']
        this.epService.get(_id).subscribe(res => {

          if(res.ep && res.ep['year'] && res.ep['year'] === this.date) {
            this.ep = res.ep;
            this.hasTrainingData = res.ep.trainings.length > 0;
            this.hasSkillsToDevelopData = res.ep['skillsToDevelop'].length > 0;
            this.hasJobHistoryData = res.ep['jobHistory'].length > 0;
            this.isUser = res.isUser;
            this.isManager = res.isManager;
            this.calculateState();

            for (let training of this.ep['trainings']) {
              if(!training['efficiency']) {
                training['efficiency'] = '1';
              }
              else {
                training['efficiency'] = training['efficiency'].toString();
              }
            }
            this.initForm(res.ep);
          }
          else {
            router.navigate(['/']);
          }
          }
        ,error => {
            if(error) {
              router.navigate(['/']);
            }
          });
      }
    });
  }

  ngOnInit(): void {

  }

  private _brandFilter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.brands.filter((brand:any) => brand.toLowerCase().indexOf(filterValue)>=0);
  }

  private _categoriesFilter(value: string): string[] {
    const filterValue = value.toLowerCase();
    console.log(filterValue)
    return this.categories.filter((category:any) => category.toLowerCase().indexOf(filterValue)>=0);
  }

  public getUserByFullName(fullname: string): any {
    for(let user of this.users) {
      if(fullname.toLowerCase() === (user.firstname + ' ' + user.lastname).toLowerCase()) {
        return user;
      }
    }
    return null;
  }

  private _usersFilter(value: any): any[] {
    let filterValue;
    if (typeof value === 'string' || value instanceof String) {
      filterValue = value.toLowerCase();
    }
    else {
      filterValue = (value.firstname + ' ' + value.lastname).toLowerCase().toLowerCase();
    }
    return this.users.filter((user:any) => (user.firstname + ' ' + user.lastname).toLowerCase().indexOf(filterValue)>=0);
  }

  public userDisplayFn(user): string {
    if(!user) return '';
    return user.firstname + ' ' + user.lastname;
  }

  public selectUser($event): void {
    this.ep['manager'] = $event.source.value;
  }

  private _categoriesEvoProFilter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.categoriesEvoPro.filter((category:any) => category.toLowerCase().indexOf(filterValue)>=0);
  }

  private _skillsFilter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.skills.filter((skill:any) => skill.toLowerCase().indexOf(filterValue)>=0);
  }



  initForm(ep: any) {
    let international = false;
    let national = false;
    let mobility = this.ep['mobility']['where'].split(',')

    for(let m of mobility) {
      if(m === 'national')
        national = true;
      if(m === 'international')
        international = true;
    }
    let epDate = ''
    if(this.isManager && this.state === this.StatesValues.SENT_TO_MANAGER)
      epDate = moment().toISOString()
    console.log(ep.manager)
    this.epFormGroup = this.formBuilder.group({
      epDate: [ep.epDate ? moment(ep.epDate).toISOString() : epDate],
      firstname: [ep.firstname.toLowerCase()],
      lastname: [ep.lastname.toLowerCase()],
      seniorityDate: [moment(ep.seniorityDate).format('DD/MM/YYYY')],
      job: [ep.job],
      legalEntity: [ep.legalEntity],
      brand: [ep.brand],
      manager: [ep.manager],
      skillCategory: [''],
      skillName: [''],
      categoryEvolutionPro: [''],
      activityEvolutionPro: [''],
      whenEvolutionPro: [''],
      isPeriodicEP: [ep.isPeriodicEP],
      isSpecialEP: [ep.isSpecialEP || !ep.isPeriodicEP ],
      mobility: [ep.mobility.isMobile?'1': '2'],
      national: [national],
      international: [international],
      userComments: [ep.userComments],
      managerComments: [ep.managerComments],
      userSign: [ep.signatures && ep.signatures.userSignDate],
      managerSign: [ ep.signatures && ep.signatures.managerSignDate]
    });

    this.usersService.listManagers().subscribe(result => {
      this.users = result.managers.map(x=>{
        x.firstname = x.firstname.toLowerCase();
        x.lastname = x.lastname.toLowerCase();
        return x;
      });
      this.filterUsers = this.epFormGroup.get('manager')!.valueChanges.pipe(
        startWith(''),
        map(value => this._usersFilter(value))
      );
      this.epFormGroup.get('manager').setValue(this.getUserByFullName(ep.manager));
    })

    this.brandsService.list().subscribe(brands => {
      this.brands = brands.map((obj:any) => obj.brand);

      this.filteredBrands = this.epFormGroup.get('brand')!.valueChanges.pipe(
        startWith(''),
        map(value => this._brandFilter(value))
      );
    });

    this.skillsService.get_categories().subscribe(response => {
      this.categories = response.categories;
      this.filterCategories = this.epFormGroup.get('skillCategory')!.valueChanges.pipe(
        startWith(''),
        map(value => this._categoriesFilter(value))
      );
      this.filterCategoriesEvoPro = this.epFormGroup.get('categoryEvolutionPro')!.valueChanges.pipe(
        startWith(''),
        map(value => this._categoriesEvoProFilter(value))
      );
    });

    if(this.epFormGroup.get('skillCategory').value.trim() !== '') {
      let cat = '';
      if(this.epFormGroup.get('skillCategory') && this.epFormGroup.get('skillCategory').value )
        cat = this.epFormGroup.get('skillCategory').value;
      this.skillsService.get_skills(cat).subscribe(response => {
        this.skills = response.skills;
        this.filterSkills = this.epFormGroup.get('skillName')!.valueChanges.pipe(
          startWith(''),
          map(value => this._skillsFilter(value))
        );
      });
    }

  }

  calculateState() {
    let managerSign = this.ep['signatures']['managerSignDate'] !== null;
    let userSign = this.ep['signatures']['userSignDate'] !== null;

    if(this.ep['sent']) {
      this.state = this.StatesValues.SENT_TO_MANAGER;
    }
    if(managerSign)
    {
      this.state = this.StatesValues.SIGNED_BY_MANAGER;
    }
    if(userSign) {
      this.state = this.StatesValues.TERMINATE;
    }
  }

  parseDate(date: string) {
    return Date.parse(date);
  }

  addSkill() {
    if(this.epFormGroup.get('skillName').value.trim() !== '' && this.epFormGroup.get('skillCategory').value.trim() !== '') {
      this.ep['masteredSkills'].push({category: this.epFormGroup.get('skillCategory').value, skill: this.epFormGroup.get('skillName').value})
      this.epFormGroup.get('skillCategory').setValue('');
      this.epFormGroup.get('skillName').setValue('');
    }
  }

  removeSkill(index: number) {
    this.ep['masteredSkills'].splice(index, 1);
  }

  addEvolutionPro() {
    if(this.epFormGroup.get('activityEvolutionPro').value.trim() !== '' && this.epFormGroup.get('categoryEvolutionPro').value.trim() !== '' && this.epFormGroup.get('whenEvolutionPro').value.trim() !== '') {

      this.ep['professionalEvolution'].push({category: this.epFormGroup.get('categoryEvolutionPro').value,
        activity: this.epFormGroup.get('activityEvolutionPro').value,
        when: this.epFormGroup.get('whenEvolutionPro').value
      })
    }
  }

  removeEvoPro(index: number) {
    this.ep['professionalEvolution'].splice(index, 1);
  }

  selectCategory($event: any) {
      this.skillsService.get_skills($event.source.value).subscribe(response => {
        this.skills = response.skills;
        this.filterSkills = this.epFormGroup.get('skillName')!.valueChanges.pipe(
          startWith(''),
          map(value => this._skillsFilter(value))
        );
      });
  }

  addLineInTrainings() {
    this.ep['trainings'].push({category: '', name: '', efficiency: '1', comment: ''});
    this.trainingTable.renderRows();
  }

  addLineInSkillsToDevelop() {
    this.ep['skillsToDevelop'].push({category: '', skill: '', priority: '1'});
    this.skillsToDevelopTable.renderRows();
  }

  isSkillsToDevelopValid() {
    if(this.ep && this.ep['skillsToDevelop']) {
      for(let skill of this.ep['skillsToDevelop']) {

        if(skill.category.trim() === '' || skill.skill.trim() === '') {
          return false;
        }

      }
    }

    return true;
  }

  isTrainingsValid() {
    if(this.ep && this.ep['trainings']) {
      for(let training of this.ep['trainings']) {

        if(training.category.trim() === '' || training.name.trim() === '') {
          return false;
        }

      }
    }

    return true
  }

  removeTraining(index) {

    this.ep['trainings'].splice(index, 1);
    this.trainingTable.renderRows();
  }

  removeSkillToDevelop(index) {

    this.ep['skillsToDevelop'].splice(index, 1);
    this.skillsToDevelopTable.renderRows();
  }

  addLineInJobHistory() {
    this.ep['jobHistory'].push({job: '',legalEntity: '', startDate: null, endDate: null});
    this.jobHistoryTable.renderRows();
  }

  isJobHistoryValid() {
    if(this.ep && this.ep['jobHistory']) {
      for (let job of this.ep['jobHistory']) {

        if (job.job.trim() === '' || job.legalEntity.trim() === '' || !job.startDate) {
          return false;
        }

        if(job.startDate && job.endDate && moment(job.endDate) < moment(job.startDate)) {
          return false;
        }

      }
    }
    return true;
  }

  isOtherElementsValid() {
    return this.isJobHistoryValid() && this.isTrainingsValid() && this.isSkillsToDevelopValid();
  }


  removeJob(index) {

    this.ep['jobHistory'].splice(index, 1);
    this.jobHistoryTable.renderRows();
  }

  mobilityCHange($event) {


    this.ep['mobility']['isMobile'] = $event.value === '1';
    if($event.value === '1') {
      let mobility = []
      if(this.epFormGroup.get('national').value)
        mobility.push('national');
      if(this.epFormGroup.get('international').value)
        mobility.push('international');
      this.ep['mobility']['where'] = mobility.join(',');
    }
    else {
      this.ep['mobility']['where'] = '';
      this.epFormGroup.get('national').setValue(false);
      this.epFormGroup.get('international').setValue(false);
    }

  }

  mobilityCHangeWhere($event) {
    let mobility = []
    if(this.epFormGroup.get('national').value)
      mobility.push('national');
    if(this.epFormGroup.get('international').value)
      mobility.push('international');
    this.ep['mobility']['where'] = mobility.join(',');

  }

  signValidation() {
    if(this.isManager) {
      this.epService.managerSign(this.ep).subscribe(res => {
          if(res.success) {
            this.snackBar.open("La signature électronique a été transmise avec succès", null, {duration:3000} );
            this.router.navigate(['/space'], { queryParams: { year: this.date } });
          }
      });
    }

    if(this.isUser) {
      this.epService.userSign(this.ep).subscribe(res => {
        this.snackBar.open("La signature électronique a été transmise avec succès", null, {duration:3000} );
        this.router.navigate(['/space'], { queryParams: { year: this.date } });
      });
    }
  }

  sendToManager() {
    this.epService.sendToManager(this.ep).subscribe(res => {
      this.snackBar.open("L'entretien professionel a été transmis à votre manager", null, {duration:3000} );
      this.router.navigate(['/space'], { queryParams: { year: this.date } });
    })
  }

  unvalidate() {
    this.epService.unvalidate(this.ep).subscribe(res => {
      this.snackBar.open("L'entretien professionel a été retourné au collaborateur", null, {duration:3000} );
      this.router.navigate(['/space'], { queryParams: { year: this.date } });
    })
  }

  saveForLater() {
    this.epService.saveForLater(this.ep).subscribe(res => {
      this.snackBar.open("L'entretien professionel a été sauvegardé avec succès", null, {duration:3000} );
      this.router.navigate(['/space'], { queryParams: { year: this.date } });
    })
  }

  commentChange($event, commentField) {
    this.ep[commentField] = $event.target.value;
  }

  interviewDateChange($event) {
    this.ep['epDate'] = $event.target.value;

  }

  print() {
    this.epService.getPdf(this.ep, this.date).subscribe(res => {
      if(res.uuid) {
        let a = document.createElement('a');
        let token = localStorage.getItem("access_token");
        let paramMap  = new HttpParams().set('token', token).set('uuid', res.uuid);
        a.href =  "/api/ep/download/ep_" + this.normalize(this.ep.firstname + '_' + this.ep.lastname) + '_' + this.date + ".pdf?" + paramMap.toString() ;

        a.target = "_blank";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    });
  }

  normalize(str) {
    return str.normalize("NFD").replace(/\p{Diacritic}/gu, "")
  }

  getNow() {
    return moment().toISOString();
  }

  modelChange($event) {

  }

  public capitalize(value: string): string {
    return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
  }
}
