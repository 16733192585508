import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {BaseService} from './base-http.service';

@Injectable({
  providedIn: 'root'
})
export class EPService extends BaseService {

  constructor(
    private httpClient: HttpClient
  ) {
    super();
  }

  public get(id: any): Observable<any> {
    return this.httpClient.get<any>('/api/ep/' + id);
  }

  public sendToManager(ep:any): Observable<any> {
    return this.httpClient.post('/api/ep/send_to_manager', ep);
  }

  public saveForLater(ep:any): Observable<any> {
    return this.httpClient.post('/api/ep/save_for_later', ep);
  }

  public userSign(ep:any): Observable<any> {
    return this.httpClient.post('/api/ep/user_sign', ep);
  }

  public managerSign(ep:any): Observable<any> {
    return this.httpClient.post('/api/ep/manager_sign', ep);
  }

  public unvalidate(ep:any): Observable<any> {
    return this.httpClient.post('/api/ep/unvalidate', ep);
  }

  public getPdf(ep: any, year): Observable<any> {
    return this.httpClient.post('/api/ep/pdf?year=' + year, ep);
  }
}
