<div class="page"
     [ngClass]="theme.value"
     [ngStyle]="{'background': 'transparent url(\'assets/images/bgs/'+ backgroundsService.backgroundsSource.value +'.png\') no-repeat'}"
>
    <div class="header">
      <div>
        <div [routerLink]="['/']">
          <span>EP</span>
          <span>BP</span>
        </div>

        <div class="separator"></div>

        <div [routerLink]="['/']">
          <span>Entretien Professionnel</span>
          <span>Bilan Professionnel</span>
        </div>
      </div>

      <div class="top-menu">
        <span (click)="openBlank('https://forms.office.com/Pages/ResponsePage.aspx?id=7JKJbtV2pU6OrrDF5Vh0mkMh7IgoxKZIkS4Zo8AO6bpUQ05TVkoyUTU2TUcyUFFQVk1VSEZVOVlHTyQlQCN0PWcu')">Contactez nous </span>
        <span [routerLink]="['/faq']">FAQ</span>
        <span *ngIf="user && user.isRH === true" [routerLink]="['/rh']">Accès RH</span>
        <span (click)="logout()">Déconnexion</span>
      </div>
    </div>

    <div class="page-content">
      <app-breadcrumb></app-breadcrumb>
      <router-outlet></router-outlet>
    </div>

    <div class="footer">
      <div>
        <span>EPBP</span>&nbsp;
        <span>BY IPROSPECT R&D</span>
      </div>

      <img width="60px" src="assets/images/dentsu.png" />
    </div>
</div>
