import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UsersService} from "../../services/users.service";

@Component({
  selector: 'app-space',
  templateUrl: './space.component.html',
  styleUrls: ['./space.component.scss']
})
export class SpaceComponent implements OnInit {
  public dateSpace = '';
  public isManager = false;
  public hasManagerActions = false;
  public ep = null;
  public bp = null;

  constructor(
    private route: ActivatedRoute,
    private usersService: UsersService,
    private router: Router
  ) {
    this.route.queryParams.subscribe(params => {
        if (params['year'] && /^[0-9]{4}$/.test(params['year'])) {

          this.dateSpace = params['year'];

          this.usersService.getMe().subscribe(res => {
            if(res.me) {
              let user = res.me;
              if(user.ep && user.ep[this.dateSpace]) {
                let len = user.ep[this.dateSpace].length;
                this.ep = user.ep[this.dateSpace][len - 1];
              }

              if(user.bp && user.bp[this.dateSpace]) {
                let len = user.bp[this.dateSpace].length;
                this.bp = user.bp[this.dateSpace][len - 1];
              }

              if(user.isManager) {
                this.isManager = true;

                this.usersService.hasManagerActions(this.dateSpace).subscribe(resActions => {
                  if(resActions.hasActions === true) {
                    this.hasManagerActions = true;
                  }
                });
              }
            }
          })
        }
        else {
          this.router.navigate(['/']);
        }
    });
  }

  ngOnInit(): void {
  }

  public isCircleDisabled(obj) {
    if(!obj) {
      return true;
    }
    return this.hasBecomingAction(obj);
  }

  public hasBecomingAction(obj) {
    if(obj['startDate'] && Date.parse(obj['startDate']) > Date.now()) {
      return true;
    }
    return false
  }

  public linkTo(aim: string): void {
    if (aim === 'ep' && this.ep) {
      this.router.navigate(['/ep'], {queryParams: {
          year: this.dateSpace,
          id: (this.ep.id ? this.ep.id : this.ep._id)
        }});
    }

    if (aim === 'bp' && this.bp) {
      this.router.navigate(['/bp'], {queryParams: {
          year: this.dateSpace,
          id: (this.bp.id ? this.bp.id : this.bp._id)
        }});
    }

    if (aim === 'collaborators') {
      this.router.navigate(['/collaborators'], {queryParams: {
          year: this.dateSpace,
          view: 'manager'
        }});
    }

    return null;
  }

  parseDate(date: string) {
    return Date.parse(date);
  }

}
