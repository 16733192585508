import {OverlayContainer} from '@angular/cdk/overlay';
import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
// import {AuthenticationService} from './services/authentication.service';
import {Theme, ThemeService} from './services/theme.service';
import {FormControl} from "@angular/forms";
import {FiltersService} from "./services/filters.service";
import {SkillsService} from "./services/skills.service";
import {ScopesService} from "./services/scopes.service";
import { AuthenticationService } from './services/authentication.service';
import {UsersService} from "./services/users.service";
import {BackgroundsService} from "./services/backgrounds.services";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public theme: Theme;
  public themes: Theme[] = [];

  public user: any;


  constructor(
    private themeService: ThemeService,
    private overlayContainer: OverlayContainer,
    private router: Router,
    private usersService: UsersService,
    public authenticationService: AuthenticationService,
    public backgroundsService: BackgroundsService
  ) {
    this.theme = this.themeService.getDefaultTheme();
    this.overlayContainerTheming(this.theme.value);
  }

  ngOnInit(): void {
    this.themes = this.themeService.getThemes();
    this.themeService.getTheme().subscribe((next) => {
      this.theme = next;
    });

    this.authenticationService.logged.subscribe(e => {
      if (!e)
        return;

      this.usersService.getMe().subscribe(res => {
        if(res.me) {
          this.user = res.me;
        }
      })
    });
  }

  public toggleTheme(value: string): void {
    this.overlayContainerTheming(value);
    this.themeService.setTheme(value);
  }

  private overlayContainerTheming(themeClassName: string): void {
    const cl = this.overlayContainer.getContainerElement().classList;

    if (cl.length > 1) {
      this.overlayContainer.getContainerElement().classList.remove(<string> cl.item(cl.length - 1));
    }

    this.overlayContainer.getContainerElement().classList.add(themeClassName);
  }

  public logout() {
    this.authenticationService.logout().subscribe(res => {
      window.location.reload();
    });
  }

  public openBlank(url: string) {
    window.open(url, '_blank');
  }
}
