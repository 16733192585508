import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rh-space',
  templateUrl: './rh-space.component.html',
  styleUrls: ['./rh-space.component.scss']
})
export class RhSpaceComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  public linkTo(aim: string): void {
    if (aim === 'collaborators') {
      window.open('/collaborators?view=rh');
    }

    return null;
  }

}
