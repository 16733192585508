import { Component, OnInit } from '@angular/core';
import {NavigationEnd, NavigationStart, Router} from "@angular/router";

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {

  public links = []
  previousUrl = '';
  currentUrl = '';
  constructor(private router: Router) {
    this.router.events.forEach((event) => {

      if(event instanceof NavigationStart) {

       this.buildBreadcrumb(event.url)
      }
      if(event instanceof NavigationEnd) {

        this.buildBreadcrumb(event.url)

      }
    });
  }

  ngOnInit(): void {
  }

  extractArgs(url) {
    let spt = url.split('?')

    let args = {}

    if(spt.length > 1) {
      for(let val of spt[1].split('&')) {
        let spt2 = val.split('=');
        args[spt2[0]] = spt2[1]
      }
    }
    return args;
  }

  buildBreadcrumb(url) {
    let args = this.extractArgs(url)

    if(url === '/') {
      this.links = [];
    }
    if(url.startsWith('/rh')) {
      this.links = [{
        title: 'Espace RH',
        url: '/rh',
        query:{}
      }];
    }
    if(url.startsWith('/rh')) {
      this.links = [{
        title: 'Espace RH',
        url: url,
        query:{}
      }];
    }

    if(url.startsWith('/statistics')) {
      this.links = [{
        title: 'Espace RH',
        url: '/rh',
        query:{}
      },
        {
          title: 'Statistiques Utilisateurs',
          url: url,
          query:{}
        }];
    }

    if(url.startsWith('/synchro-bdd')) {
      this.links = [{
        title: 'Espace RH',
        url: '/rh',
        query:{}
      },
        {
          title: 'Informations Bases de Données',
          url: url,
          query:{}
        }];
    }

    if(url.startsWith('/synchro-bdd')) {
      this.links = [{
        title: 'Espace RH',
        url: '/rh',
        query:{}
      },
        {
          title: 'Informations Bases de Données',
          url: url,
          query:{}
        }];
    }

    if(url.startsWith('/collaborators') && args['view'] && args['view'] === 'rh') {
      this.links = [{
        title: 'Espace RH',
        url: '/rh',
        query:{}
      },
        {
          title: 'Liste des Collaborateurs',
          url: url,
          query:{}
        }];
    }

    if(url.startsWith('/faq')) {
      this.links = [{
        title: 'FAQ',
        url: url,
        query:{}
      }];
    }

    if(url.startsWith('/collaborators') && args['view'] && args['view'] === 'manager') {
      this.links = [{
        title: 'Espace ' + args['year'],
        url: '/space',
        query : {year:args['year']}
      },
        {
          title: 'Liste des Collaborateurs',
          url: url
        }];
    }

    if(url.startsWith('/ep')) {
      this.links = [{
        title: 'Espace ' + args['year'],
        url: '/space',
        query : {year:args['year']}
      },
        {
          title: 'Entretien Professionnel ' + args['year'],
          url: url
        }];
    }

    if(url.startsWith('/bp')) {
      this.links = [{
        title: 'Espace ' + args['year'],
        url: '/space',
        query : {year:args['year']}
      },
        {
          title: 'Bilan Professionnel ' + args['year'],
          url: url
        }];
    }

    if(url.startsWith('/space')) {
      let year = url.split('=')[1]
      this.links = [
        {
          title: 'Espace '+ year,
          url: url
        }];
    }
  }

}
