import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {BPService} from "../../services/bp.service";
import {FormBuilder, FormGroup} from "@angular/forms";
import * as moment from "moment";
import {map, startWith} from "rxjs/operators";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatTable} from "@angular/material/table";
import {Observable} from "rxjs";
import {BrandsService} from "../../services/brands.service";
import {HttpParams} from "@angular/common/http";
import {UsersService} from "../../services/users.service";

@Component({
  selector: 'app-bp',
  templateUrl: './bp.component.html',
  styleUrls: ['./bp.component.scss']
})
export class BpComponent implements OnInit {
  public date = 0;

  public StatesValues = {
    NOT_STARTED: 1,
    SENT_TO_MANAGER: 2,
    SIGNED_BY_MANAGER: 3,
    TERMINATE: 4
  }

  public bp: any;
  public today = null;
  public minDate = null;
  public isUser = false;
  public isManager = false;
  public state = this.StatesValues.NOT_STARTED;

  public bpFormGroup: FormGroup;

  public step = 0;

  public professionalEvolutionHeader = ['position_held', 'brand', 'start_date', 'end_date', 'action']

  public formationActionsHeaders = ['name', 'duration', 'year', 'action'];

  public filteredBrands: Observable<string[]> = new Observable<string[]>();
  public brands: any = [];

  public filterUsers: Observable<any[]> = new Observable<any[]>();
  public users: any = [];

  @ViewChild('jobHistoryTable') jobHistoryTable: MatTable<string>;
  @ViewChild('trainingTable') trainingTable: MatTable<string>;

  constructor(
    private route: ActivatedRoute,
    private bpService: BPService,
    private brandsService: BrandsService,
    private usersService: UsersService,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private router: Router
  ) {
    this.bpFormGroup = this.formBuilder.group({
      bpDate: [''],
      firstname: [''],
      lastname: [''],
      seniorityDate: [''],
      job: [''],
      legalEntity: [''],
      brand: [''],
      manager: [''],
      comments: [''],
      userComments: [''],
      managerComments: [''],
      userSign: [''],
      managerSign: ['']
    })

    this.today = moment().toISOString()
    this.minDate = moment().subtract(6,'year').toISOString()
    this.route.queryParams.subscribe(params => {
      if (params['year']) {
        this.date = params['year'];
        let _id = params['id']
        this.bpService.get(_id).subscribe(res => {

          if(res.bp && res.bp['year'] && res.bp['year'] === this.date) {
            this.bp = res.bp;
            this.isUser = res.isUser;
            this.isManager = res.isManager;
            this.calculateState();

            this.initForm(res.bp);
          }
          else {
            router.navigate(['/']);
          }
        },error => {
          if(error) {
            router.navigate(['/']);
          }
        });
      }
    });
  }

  ngOnInit(): void {
  }

  public getUserByFullName(fullname: string): any {
    for(let user of this.users) {
      if(user.lastname.toLowerCase() ==='jean')
        console.log(fullname.toLowerCase(), (user.firstname + ' ' + user.lastname).toLowerCase());
      if(fullname.toLowerCase() === (user.firstname + ' ' + user.lastname).toLowerCase()) {
        return user;
      }
    }
    return null;
  }

  private _usersFilter(value: any): any[] {
    let filterValue;
    if (typeof value === 'string' || value instanceof String) {
      filterValue = value.toLowerCase();
    }
    else {
      filterValue = (value.firstname + ' ' + value.lastname).toLowerCase().toLowerCase();
    }
    return this.users.filter((user:any) => (user.firstname + ' ' + user.lastname).toLowerCase().indexOf(filterValue)>=0);
  }

  public userDisplayFn(user): string {
    return user.firstname + ' ' + user.lastname;
  }

  public selectUser($event): void {
    this.bp['manager'] = $event.source.value;
  }

  initForm(bp: any) {
    let bpDate = ''
    if(this.isManager && this.state === this.StatesValues.SENT_TO_MANAGER)
      bpDate = moment().toISOString()
    this.bpFormGroup = this.formBuilder.group({
      bpDate: [bp.bpDate ? moment(bp.bpDate).toISOString(): bpDate],
      firstname: [bp.firstname.toLowerCase()],
      lastname: [bp.lastname.toLowerCase()],
      seniorityDate: [moment(bp.seniorityDate).format('DD/MM/YYYY')],
      job: [bp.job],
      legalEntity: [bp.legalEntity],
      brand: [bp.brand],
      manager: [bp.manager],
      comments: [bp.comments],
      userComments: [bp.userComments],
      managerComments: [bp.managerComments],
      userSign: [bp.signatures && bp.signatures.userSignDate],
      managerSign: [bp.signatures && bp.signatures.managerSignDate]
    });

    this.usersService.listManagers().subscribe(result => {
      this.users = result.managers.map(x=>{
        x.firstname = x.firstname.toLowerCase();
        x.lastname = x.lastname.toLowerCase();
        return x;
      });
      this.filterUsers = this.bpFormGroup.get('manager')!.valueChanges.pipe(
        startWith(''),
        map(value => this._usersFilter(value))
      );
      this.bpFormGroup.get('manager').setValue(this.getUserByFullName(bp.manager));
    })

    this.brandsService.list().subscribe(brands => {
      this.brands = brands.map((obj:any) => obj.brand);

      this.filteredBrands = this.bpFormGroup.get('brand')!.valueChanges.pipe(
        startWith(''),
        map(value => this._brandFilter(''))
      );
    });
  }

  calculateState() {
    let managerSign = this.bp['signatures']['managerSignDate'] !== null;
    let userSign = this.bp['signatures']['userSignDate'] !== null;

    if(this.bp['sent']) {
      this.state = this.StatesValues.SENT_TO_MANAGER;
    }
    if(managerSign)
    {
      this.state = this.StatesValues.SIGNED_BY_MANAGER;
    }
    if(userSign) {
      this.state = this.StatesValues.TERMINATE;
    }
  }

  private _brandFilter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.brands.filter((brand:any) => brand.toLowerCase().indexOf(filterValue)>=0);
  }

  public brandChange(element) {
    this.filteredBrands = new Observable<string[]>().pipe( startWith(''),
      map(value => this._brandFilter(element)));
  }


  parseDate(date: string) {
    return Date.parse(date);
  }

  signValidation() {
    if(this.isManager) {
      this.bpService.managerSign(this.bp).subscribe(res => {
        if(res.success) {
          this.snackBar.open("La signature électronique a été transmise avec succès", null, {duration:3000} );
          this.router.navigate(['/space'], { queryParams: { year: this.date } });
        }
      });
    }

    if(this.isUser) {
      this.bpService.userSign(this.bp).subscribe(res => {
        this.snackBar.open("La signature électronique a été transmise avec succès", null, {duration:3000} );
        this.router.navigate(['/space'], { queryParams: { year: this.date } });
      });
    }
  }

  sendToManager() {
    this.bpService.sendToManager(this.bp).subscribe(res => {
      this.snackBar.open("Le bilan professionel a été transmis à votre manager", null, {duration:3000} );
      this.router.navigate(['/space'], { queryParams: { year: this.date } });
    })
  }

  addLineInJobHistory() {
    this.bp['jobHistory'].push({job: '',legalEntity: '', startDate: '', endDate: ''});
    this.jobHistoryTable.renderRows();
    this.brandChange('')
  }

  removeJob(index) {
    this.bp['jobHistory'].splice(index, 1);
    this.jobHistoryTable.renderRows();
  }

  addLineInTrainings() {
    this.bp['trainings'].push({name: '',hours: '1', year: (this.date - 6)});
    this.trainingTable.renderRows();
  }

  removeTraining(index) {
    this.bp['trainings'].splice(index, 1);
    this.trainingTable.renderRows();
  }

  isJobHistoryValid() {
    if(this.bp && this.bp['jobHistory']) {
      for (let job of this.bp['jobHistory']) {

        if (job.job.trim() === '' || job.legalEntity.trim() === '' || !job.startDate) {
          return false;
        }
        if(job.startDate && job.endDate && moment(job.endDate) < moment(job.startDate)) {
          return false;
        }
      }
    }
    return true;
  }

  isTrainingsValid() {
    if(this.bp && this.bp['trainings']) {
      for(let training of this.bp['trainings']) {

        if(training.name.trim() === '' || training.hours === ''
          || training.hours <=0 || training.date === '' || training.date > moment().year() || training.date < this.date-6) {
          return false;
        }


      }
    }

    return true
  }

  isOtherElementsValid() {
    return this.isJobHistoryValid() && this.isTrainingsValid();
  }

  saveForLater() {
    this.bpService.saveForLater(this.bp).subscribe(res => {
      this.snackBar.open("Le bilan professionel a été sauvegardé avec succès", null, {duration:3000} );
      this.router.navigate(['/space'], { queryParams: { year: this.date } });
    })
  }

  unvalidate() {
    this.bpService.unvalidate(this.bp).subscribe(res => {
      this.snackBar.open("Le bilan professionel a été retourné au collaborateur", null, {duration:3000} );
      this.router.navigate(['/space'], { queryParams: { year: this.date } });
    })
  }

  commentChange($event, commentField) {
    this.bp[commentField] = $event.target.value;
  }

  interviewDateChange($event) {
    this.bp['bpDate'] = $event.target.value;

  }

  normalize(str) {
    return str.normalize("NFD").replace(/\p{Diacritic}/gu, "")
  }

  print() {
    this.bpService.getPdf(this.bp, this.date).subscribe(res => {
      if(res.uuid) {
        let a = document.createElement('a');
        let token = localStorage.getItem("access_token");
        let paramMap  = new HttpParams().set('token', token).set('uuid', res.uuid);
        a.href =  "/api/bp/download/bp" + this.normalize(this.bp.firstname + '_' + this.bp.lastname) + '_' + this.date + ".pdf?" + paramMap.toString() ;
        a.target = "_blank";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    });
  }
}
