// moment.js locale configuration
// locale : French [fr]

import * as momentNs from 'moment-timezone';
const moment = momentNs;

const monthsRegex = /^(janvier|février|mars|avril|mai|juin|juillet|août|septembre|octobre|novembre|décembre)/i;
const monthsShortRegex = /^(janv\.?|févr\.?|mars\.?|avri\.?|mai\.?|juin\.?|juil\.?|août\.?|sept\.?|octo\.?|nove\.?|déce\.?)/i;
const monthsParse = [
  /^janv/i,
  /^févr/i,
  /^mars/i,
  /^avri/i,
  /^mai/i,
  /^juin/i,
  /^juil/i,
  /^août/i,
  /^sept/i,
  /^octo/i,
  /^nove/i,
  /^déce/i
];

const fr = {
  months: 'Janvier Février Mars Avril Mai Juin Juillet Août Septembre Octobre Novembre Décembre'.split(
    ' '
  ),
  monthsShort: 'Janv Févr Mars Avri Mai Juin Juil Août Sept Octo Nove Déce'.split(' '),
  monthsRegex: new RegExp(monthsRegex.source + monthsShortRegex.source, 'i'),
  monthsShortRegex: monthsRegex,
  monthsStrictRegex: monthsRegex,
  monthsShortStrictRegex: monthsShortRegex,
  monthsParse: monthsParse,
  longMonthsParse: monthsParse,
  shortMonthsParse: monthsParse,
  weekdays: 'Dimanche Lundi Mardi Mercredi Jeudi Vendredi Samedi'.split(' '),
  weekdaysShort: 'Dim Lun Mar Mer Jeu Ven Sam'.split(' '),
  weekdaysMin: 'Di Lu Ma Me Je Ve Sa'.split(' '),
  weekdaysParseExact: true,
  longDateFormat: {
    LT: 'h:mm a',
    LTS: 'h:mm:ss a',
    L: 'DD/MM/YYYY',
    l: 'D/M/YYYY',
    LL: 'MMMM D, YYYY',
    ll: 'MMM D, YYYY',
    LLL: 'MMMM D [de] YYYY, [a las] h:mm a',
    lll: 'MMM D [de] YYYY [a las] h:mm a',
    LLLL: 'dddd, MMMM D [de] YYYY [a las] h:mm a',
    llll: 'ddd, MMM D [de] YYYY [a las] h:mm a'
  },
  calendar: {
    sameDay: function (): string {
      // tslint:disable-next-line:no-invalid-this
      return '[aujourd\'hui à' + (Number(this.hours()) !== 1 ? 's' : '') + '] LT';
    },
    nextDay: function (): string {
      // tslint:disable-next-line:no-invalid-this
      return '[demain à' + (Number(this.hours()) !== 1 ? 's' : '') + '] LT';
    },
    nextWeek: function (): string {
      // tslint:disable-next-line:no-invalid-this
      return 'dddd [à' + (Number(this.hours()) !== 1 ? 's' : '') + '] LT';
    },
    lastDay: function (): string {
      // tslint:disable-next-line:no-invalid-this
      return '[hier à' + (Number(this.hours()) !== 1 ? 's' : '') + '] LT';
    },
    lastWeek: function (): string {
      // tslint:disable-next-line:no-invalid-this
      return '[le] dddd [dernier à' + (Number(this.hours()) !== 1 ? 's' : '') + '] LT';
    },
    sameElse: 'L',
    hours: () => '' // lint workaround
  },
  relativeTime: {
    future: 'dans %s',
    past: 'il y a %s',
    ss: 'quelques secondes',
    s: 'une seconde',
    m: 'une minute',
    mm: '%d minutes',
    h: 'una heure',
    hh: '%d heure',
    d: 'un jour',
    dd: '%d jours',
    M: 'un mois',
    MM: '%d mois',
    y: 'une année',
    yy: '%d années'
  },
  dayOfMonthOrdinalParse: /\d{1,2}º/,
  ordinal: function (n: number): string {
    return `${n}º`;
  },
  week: {
    dow: 0, // CCR: Sunday as first day of the week.
    doy: 4 // The week that contains Jan 4th is the first week of the year.
  }
};

// ('fr') IMPORTANT means spanish
moment.defineLocale('fr', fr);
moment.locale('fr');
