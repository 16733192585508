<div class="loader-dot-container l-screen" *ngIf="loading && loadingType == 'screen'">
  <div class="wrapper">
    <div class="circle"></div>
    <div class="circle"></div>
    <div class="circle"></div>
    <div class="shadow"></div>
    <div class="shadow"></div>
    <div class="shadow"></div>
    <span>Loading</span>
  </div>
</div>
<div class="loader-dot-container l-table" *ngIf="loading && loadingType == 'table'">
  <div class="wrapper">
    <div class="circle"></div>
    <div class="circle"></div>
    <div class="circle"></div>
    <div class="shadow"></div>
    <div class="shadow"></div>
    <div class="shadow"></div>
    <span>Loading</span>
  </div>
</div>
<div class="loader-content" *ngIf="loading && loadingType == 'content'"></div>
