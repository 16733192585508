import {Injectable} from '@angular/core';

import * as _ from 'lodash';
import {Observable, Subject} from 'rxjs';

export interface Theme {
  value: string;
  viewValue: string;
}

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  private themes: Theme[] = [
    {value: 'default-theme', viewValue: 'default'},
  ];

  private theme: Subject<Theme> = new Subject<Theme>();

  public getThemes(): Theme[] {
    return this.themes;
  }

  public getDefaultTheme(): Theme {
    return this.themes[0];
  }

  public getTheme(): Observable<Theme> {
    return this.theme;
  }

  public setTheme(selectedTheme: string): void {
    const theme = _.find(this.themes, {value: selectedTheme});
    this.theme.next(theme);
  }
}
