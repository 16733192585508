import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {UsersService} from "../../services/users.service";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {FormControl} from "@angular/forms";
import {debounceTime} from "rxjs/operators";
import {ActivatedRoute} from "@angular/router";
import {HttpParams} from "@angular/common/http";
import {AuthenticationService} from "../../services/authentication.service";

@Component({
  selector: 'app-collaborators-list',
  templateUrl: './collaborators-list.component.html',
  styleUrls: ['./collaborators-list.component.scss']
})
export class CollaboratorsListComponent implements OnInit, AfterViewInit {

  selectedDate = null;
  availableDates = []
  dates = {last: '', current: '', next: ''};
  public date = '';
  public stateMatches = {
    'NOT STARTED': 'A remplir par le collaborateur',
    'SENT TO MANAGER': 'A signer par le manager',
    'SIGNED BY MANAGER': 'A signer par le collaborateur',
    'COMPLETE': 'Terminé'
  }

  type = 'ep';

  public filter: FormControl = new FormControl();
  public dataLength = 0;

  public managerActions = null;

  public view: any;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('sortEP') sortEP: MatSort;
  @ViewChild('sortBP') sortBP: MatSort;

  public headersEP = ['firstname', 'lastname', 'email', 'brand', 'seniority_date', 'ep', 'ep_state', 'state_update_date']
  public headersBP = ['firstname', 'lastname', 'email', 'brand', 'seniority_date', 'bp', 'bp_state', 'state_update_date']

  public users = [];

  constructor(
    private usersService: UsersService,
    private authService: AuthenticationService,
    private route: ActivatedRoute
  ) {
    let now = new Date();
    for (let d = 2020; d <= now.getFullYear() + 1; d++) {
      this.availableDates.push(d);
    }
    this.selectedDate = now.getFullYear().toString();
    console.log(this.selectedDate)
    this.dates = {
      current: now.getFullYear().toString(),
      last: (now.getFullYear() - 1).toString(),
      next: (now.getFullYear() + 1).toString()
    };

    this.route.queryParams.subscribe(params => {
      this.date = this.dates.current;

      if(params['view']) {
        if(params['year'])
          this.date = params['year'];
        this.view = params['view'];
        if(this.date) {
          this.selectedDate = this.date;
        }

        this.getData(this.selectedDate);
      }

    });
  }

  ngOnInit(): void {


  }

  ngAfterViewInit(): void {
    this.paginator.page.pipe(debounceTime(100)).subscribe(e => this.getData(this.selectedDate));
    this.filter.valueChanges.subscribe(e => this.getData(this.selectedDate));
  }

  download() {
    let sortColumn = '';
    let sortDirection = '';

    if(this.type === 'ep') {
      if (this.sortEP && this.sortEP.active && this.sortEP.direction) {
        sortColumn = this.sortEP.active;
        sortDirection = this.sortEP.direction;
      }
    }
      else {
        if (this.sortBP && this.sortBP.active && this.sortBP.direction) {
          sortColumn = this.sortBP.active;
          sortDirection = this.sortBP.direction;
        }
      }


    this.usersService.exportCollaborators(this.view, this.selectedDate, this.filter.value, sortColumn, sortDirection).subscribe(res =>{
      if(res.filename) {
        let a = document.createElement('a');
        let token = localStorage.getItem("access_token");
        let paramMap  = new HttpParams().set('token', token);
        a.href =  "/api/users/download/" + res.filename + "?" + paramMap.toString() ;
        a.target = "_blank";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    });
  }

  downloadAllEPBP() {
    let a = document.createElement('a');
    let token = localStorage.getItem("access_token");
    let paramMap  = new HttpParams().set('token', token);
    a.href =  "/api/users/export_all_epbp/" + this.selectedDate + "?" + paramMap.toString() ;
    a.target = "_blank";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  public getData(year, resetPagination=false) {
    if(this.view === 'manager') {
      this.usersService.hasManagerActions(this.selectedDate).subscribe(actionResult => {
        if(actionResult.hasActions){
          this.managerActions = actionResult;
        }
      });
    }
    let sortColumn = '';
    let sortDirection = '';
    if(this.type === 'ep') {
      if (this.sortEP && this.sortEP.active && this.sortEP.direction) {
        sortColumn = this.sortEP.active;
        sortDirection = this.sortEP.direction;
      }
    }
    else {
      if (this.sortBP && this.sortBP.active && this.sortBP.direction) {
        sortColumn = this.sortBP.active;
        sortDirection = this.sortBP.direction;
      }
    }



    let skip = 0;
    let limit = 10;
    if (this.paginator)
    {
      if(resetPagination) {
        this.paginator.pageIndex = 0;
      }
      limit = this.paginator.pageSize;
      skip = this.paginator.pageIndex * this.paginator.pageSize;
    }


    this.usersService.getCollaborators(this.view, this.type,skip, limit, this.selectedDate, this.filter.value, sortColumn, sortDirection).subscribe(res => {
      if(res.users) {

        let users = res.users;

        for(let user of users) {
          if(user.ep && user.ep[this.selectedDate] && user.ep[this.selectedDate].length>0) {
            user.epEligible = true;
            let len = user.ep[this.selectedDate].length;
            let ep = user.ep[this.selectedDate][len - 1];
            if(this.hasBecomingAction(ep)) {
              user.epState = 'A venir';
              user.epStateDate = ep.startDate;
            }
            else {
              user.epState = this.stateMatches[ep.state.replaceAll('_', ' ')];
              if(ep.state !== 'COMPLETE' && ep['is_late'] && ep['is_late'] === true) {
                user.epState += '(En retard)';
              }
              if(ep.startDate) {
                user.epStateDate = new Date(new Date(this.parseDate(ep.startDate)).setMonth(new Date(this.parseDate(ep.startDate)).getMonth()+3))
              }
              else {
                user.epStateDate = ep.date;
              }

            }

            user.epId = ep._id?ep._id: ep.id;
          }
          else {
            user.epEligible = false;
            user.epState = 'Non éligible';
            user.epStateDate = null;
          }
        }
        for(let user of users) {
          if(user.bp && user.bp[this.selectedDate] && user.bp[this.selectedDate].length>0) {
            user.bpEligible = true;
            let len = user.bp[this.selectedDate].length;
            let bp = user.bp[this.selectedDate][len - 1];
            if(this.hasBecomingAction(bp)) {
              user.bpState = 'A venir';
              user.bpStateDate = bp.startDate;
            }
            else {

              user.bpState = this.stateMatches[bp.state.replaceAll('_', ' ')];
              if(bp.state !== 'COMPLETE' && bp['is_late'] && bp['is_late'] === true) {
                user.bpState += '(En retard)';

              }
              if(bp.startDate) {
                user.bpStateDate = new Date(new Date(this.parseDate(bp.startDate)).setMonth(new Date(this.parseDate(bp.startDate)).getMonth()+3))
              }
              else {
                user.bpStateDate = bp.date;
              }

            }
            user.bpId = bp._id?bp._id: bp.id;
          }
          else {
            user.bpEligible = false;
            user.bpState = 'Non éligible';
            user.bpStateDate = null;
          }
        }
        this.users = users;
        this.dataLength = res.dataLength;
      }
    });
  }

  changeDate($event) {
    this.selectedDate = $event.value;
    this.getData(this.selectedDate)
  }

  getUserState(element) {
    if(element.ep && (element.ep[this.selectedDate] || element.ep[parseInt(this.selectedDate)])) {
      let ep = null;
      if(element.ep[this.selectedDate]) {
        let len = element.ep[this.selectedDate].length;
        ep = element.ep[this.selectedDate][len - 1];
      }
      if(element.ep[parseInt(this.selectedDate)]) {
        let len = element.ep[parseInt(this.selectedDate)].length;
        ep = element.ep[parseInt(this.selectedDate)][len - 1];
      }

      return ep;
    }
    return null;
  }

  parseDate(date: string) {
    return Date.parse(date);
  }

  public hasBecomingAction(obj) {
    if(obj && obj['startDate'] && Date.parse(obj['startDate']) > Date.now()) {
      return true;
    }
    return false
  }

  changeTab($event) {
    this.type = $event === 0?'ep':'bp';
    this.getData(this.selectedDate);
  }

}
