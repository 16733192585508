import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {catchError, map} from 'rxjs/internal/operators';
import {Observable, of, Subject} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  public isLogged:boolean = false;
  public base = "/authentication";
  public logged:Subject<boolean> = new Subject();
  public lastUrl:string = '/';
  public authUrl = "https://auth.iphub.me/login";

  public constructor(private httpClient:HttpClient) {}

  public authenticate()
  {
    let httpParam:HttpParams = new HttpParams().set("redirect", window.origin);
    window.location.href = this.authUrl + "?" + httpParam.toString();
  }

  public checkToken()
  {
    return this.httpClient.get(this.base + '/check').pipe(
      map((e:any) => {
        this.isLogged = true;
        localStorage.setItem("access_token", e.token);
        this.logged.next(this.isLogged);
        return true;
      }),
      catchError((e) => {
      this.isLogged = false;
      this.logged.next(this.isLogged);
      return of(false);
    }));

  }

  public switchUser(id: number): Observable<boolean> {
    return this.httpClient.get(this.base + '/switch-user?id='+id).pipe(
      map((e:any) => {
        this.isLogged = true;

        localStorage.setItem("access_token", e.token);
        this.logged.next(this.isLogged);
        return (true);
      }),
      catchError((e) => {
        this.isLogged = false;
        this.logged.next(this.isLogged);

        return of(false);
      })
    );
  }

  public reset(): Observable<any> {
    return this.httpClient.get(this.base + '/reset');
  }

  public checkLogin(accessToken:string):Observable<boolean>
  {
    return this.httpClient.post(this.base + "/login", {accessToken}).pipe(
      map((e:any) => {
        this.isLogged = true;

        localStorage.setItem("access_token", e.token);
        this.logged.next(this.isLogged);
        return (true);
      }),
      catchError((e) => {
        this.isLogged = false;
        this.logged.next(this.isLogged);

        return of(false);
      })
    );
  }

  public logout() {
    localStorage.removeItem("access_token");

    this.isLogged = false;
    return of(true);
  }

}
