<div class="middle-content" >
  <div class="content-header">
    <div>
      <span>VOTRE</span>
      <span>TIMELINE</span>
    </div>

    <span>Retrouvez l’ensemble des actions effectuées au cours des dernière années.</span>
  </div>

  <div class="bubles-content">
    <div class="circle-other disabled">
      <div class="circle-inner">
        <span>Années antérieures</span>
        <div [ngStyle]="{ 'width': '70px' }"><mat-form-field>
          <mat-label>Année</mat-label>
          <mat-select (selectionChange)="selectPreviousDate($event)">
            <mat-option *ngFor="let date of previousDates" [value]="date">{{date}}</mat-option>
          </mat-select>
        </mat-form-field></div>
      </div>
    </div>

    <div class="circle-last" [routerLink]="['/space']" [queryParams]="{year: dates.last}">
      <div class="circle-inner">
        <span class="date">{{dates.last}}</span>
        <div *ngIf="actions[dates.last]">
          <mat-icon class="mdi mdi-24px mdi-alert-circle"></mat-icon>
          <span>Des actions sont requises</span>

        </div>
      </div>
    </div>

    <div class="circle-current" [routerLink]="['/space']" [queryParams]="{year: dates.current}">
      <div class="circle-inner">
        <span class="date">{{dates.current}}</span>

        <div *ngIf="actions[dates.current]">
          <mat-icon class="mdi mdi-24px mdi-alert-circle"></mat-icon>
          <span>Des actions sont requises</span>

        </div>
<!--        <span *ngIf="dates.current !== '' && hasBecomingAction(dates.current)">A venir</span>-->
      </div>
    </div>

    <div class="circle-next" [routerLink]="['/space']" [queryParams]="{year: dates.next}">
      <div class="circle-inner">
        <span class="date">{{dates.next}}</span>
        <div *ngIf="actions[dates.next]">
          <mat-icon class="mdi mdi-24px mdi-alert-circle"></mat-icon>
          <span>Des actions sont requises</span>
        </div>
        <span *ngIf="dates.next !== '' && hasBecomingAction(dates.next)">A venir</span>
      </div>
    </div>
  </div>
</div>
