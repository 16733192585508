<svg
  xmlns="http://www.w3.org/2000/svg"
  width="70"
  height="70"
  viewBox="0 0 70 70"
  version="1.1"
  id="svg860" style="overflow:visible;color:#43425d;">
  <defs id="defs854" />
  <g transform="translate(-43.559482,-92.063701)" id="layer1">
    <g id="full_obj">
      <g id="obj1">
        <path
          id="path1418"
          d="M 59.499642,140.99372 102.35155,93.063701"
          style="fill:none;stroke:#000000;stroke-width:2;stroke-linecap:round;stroke-linejoin:bevel;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:1" />
        <path
          style="fill:none;stroke:#000000;stroke-width:2;stroke-linecap:round;stroke-linejoin:bevel;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:1"
          d="M 98.125122,141.47406 60.786112,104.6105"
          id="path1418-9" />
        <path
          id="path1435"
          d="m 59.658472,141.06377 38.46665,0.41029"
          style="fill:none;stroke:#000000;stroke-width:2;stroke-linecap:round;stroke-linejoin:bevel;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:1" />
      </g>
      <g
        id="obj2">
        <path
          id="path1418-3"
          d="m 86.828802,100.39649 -42.26932,48.44457"
          style="fill:none;stroke:#000000;stroke-width:2;stroke-linecap:round;stroke-linejoin:bevel;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:1" />
        <path
          style="fill:none;stroke:#000000;stroke-width:2;stroke-linecap:round;stroke-linejoin:bevel;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:1"
          d="m 48.200352,100.38316 37.78193,36.40944"
          id="path1418-9-7" />
        <path
          id="path1435-1"
          d="m 86.669132,100.32836 -38.46878,0.0548"
          style="fill:none;stroke:#000000;stroke-width:2;stroke-linecap:round;stroke-linejoin:bevel;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:1" />
      </g>
    </g>
  </g>
</svg>

