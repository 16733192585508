import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from './components/home/home.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { AuthGuardService } from './services/auth-guard.service';
import {SpaceComponent} from './components/space/space.component';
import {FaqComponent} from './components/faq/faq.component';
import {EpComponent} from './components/ep/ep.component';
import {BpComponent} from './components/bp/bp.component';
import {CollaboratorsListComponent} from './components/collaborators-list/collaborators-list.component';
import {RhSpaceComponent} from './components/rh-space/rh-space.component';
import {StatisticsComponent} from './components/statistics/statistics.component';
import {SynchroBddComponent} from './components/synchro-bdd/synchro-bdd.component';
import {SwitchUserComponent} from './components/switch-user/switch-user.component';
// import {AuthGuardService} from './services/auth-guard.service';

const routes: Routes = [
  {path: '', component: HomeComponent, canActivate: [AuthGuardService]},
  {path: 'space', component: SpaceComponent, canActivate: [AuthGuardService]},
  {path: 'rh', component: RhSpaceComponent, canActivate: [AuthGuardService]},
  {path: 'statistics', component: StatisticsComponent, canActivate: [AuthGuardService]},
  {path: 'synchro-bdd', component: SynchroBddComponent, canActivate: [AuthGuardService]},
  {path: 'faq', component: FaqComponent, canActivate: [AuthGuardService]},
  {path: 'ep', component: EpComponent, canActivate: [AuthGuardService]},
  {path: 'bp', component: BpComponent, canActivate: [AuthGuardService]},
  {path: 'collaborators', component: CollaboratorsListComponent, canActivate: [AuthGuardService]},
  // {path: 'switch-user', component: SwitchUserComponent, canActivate: [AuthGuardService]},
   {path: 'unauthorized', component: UnauthorizedComponent},
  {path: '**', pathMatch: 'full', redirectTo: ''}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
