import { Component, OnInit } from '@angular/core';
import {UsersService} from "../../services/users.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  dates = {last: '', current: '', next:''}

  previousDates = []

  actions = {}

  public user: any;

  constructor(private usersService: UsersService, private router: Router) {
    this.usersService.getMe().subscribe(res =>{
      if(res.me) {
        this.user = res.me;

        let now = new Date();

        this.dates = {
          current: now.getFullYear().toString(),
          last: (now.getFullYear() - 1).toString(),
          next: (now.getFullYear() + 1).toString()
        };

        for( let i = 2020; i <  parseInt(this.dates.last); i++) {
          this.previousDates.push(i);
        }
        this.actions[this.dates.next] = this.hasActions(this.dates.last);

        this.actions[this.dates.current] = this.hasActions(this.dates.current);

        this.actions[this.dates.next] = this.hasActions(this.dates.next);

        this.usersService.hasManagerActions().subscribe(resActions => {
          if(resActions.hasActions === true) {
            this.actions[this.dates.current] = true;
          }
        });

        this.usersService.hasManagerActions(this.dates.next).subscribe(resActions => {
          if(resActions.hasActions === true) {
            this.actions[this.dates.next] = true;
          }
        });

      }
    });
  }

  public hasActions(year) {
    if (this.user['ep'] || this.user['bp']) {
      if (this.user['ep'][year] && this.user['ep'][year].length>0) {
        let len = this.user['ep'][year].length;
        let ep = this.user['ep'][year][len-1];
        if((ep['state'] && ep['state'] === 'NOT STARTED' || ep['state'] === 'SIGNED BY MANAGER')
          && (!ep['startDate'] ||(ep['startDate'] && Date.parse(ep['startDate']) <= Date.now()) )) {
          return true
        }
      }
    }

    if (this.user['bp']) {
      if ( this.user['bp'][year] && this.user['bp'][year].length>0) {
        let len = this.user['bp'][year].length;
        let bp = this.user['bp'][year][len-1];
        if((bp['state'] && bp['state'] === 'NOT STARTED' || bp['state'] === 'SIGNED BY MANAGER')
          && (!bp['startDate'] || (bp['startDate'] && Date.parse(bp['startDate']) <= Date.now() ))) {
          return true
        }
      }
    }

    return false;
  }

  public hasBecomingAction(year) {
    if ( this.user['ep'] && this.user['ep'][year] ) {
      let len = this.user['ep'][year].length;
      let ep = this.user['ep'][year][len - 1];

      if(ep['startDate'] && Date.parse(ep['startDate']) >= Date.now() &&
        (ep['state'] === 'NOT STARTED' || ep['state'] === 'SIGNED BY MANAGER') ) {
        return true;
      }
    }

    if ( this.user['bp'] && this.user['bp'][year] ) {
      let len = this.user['bp'][year].length;
      let bp = this.user['bp'][year][len - 1];

      if (bp['startDate'] && Date.parse(bp['startDate']) >= Date.now() &&
        (bp['state'] === 'NOT STARTED' || bp['state'] === 'SIGNED BY MANAGER') ) {
        return true;
      }
    }
    return false;
  }

  public selectPreviousDate($event) {
    this.router.navigate(['/space'],  { queryParams: { year: $event.value } });
  }

  ngOnInit(): void {

  }

}
