<div id="breadcrumb">
  <div [routerLink]="['/']">
    <mat-icon class="mdi mdi-24px mdi-map-marker"></mat-icon>
    <span [class.pointer]="links.length > 0">Timeline</span>
  </div>
  <div
    *ngFor="let link of links; let i = index"
    [routerLink]="[link.url]"
    [queryParams]="link.query"
  >
    <span>></span>
    <span [class.pointer]="i+1 < links.length">{{link.title}}</span>
  </div>
</div>
