import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {BaseService} from './base-http.service';

@Injectable({
  providedIn: 'root'
})
export class BPService extends BaseService {

  constructor(
    private httpClient: HttpClient
  ) {
    super();
  }

  public get(id: any): Observable<any> {
    return this.httpClient.get<any>('/api/bp/' + id);
  }

  public sendToManager(bp:any): Observable<any> {
    return this.httpClient.post('/api/bp/send_to_manager', bp);
  }

  public saveForLater(bp:any): Observable<any> {
    return this.httpClient.post('/api/bp/save_for_later', bp);
  }

  public userSign(bp:any): Observable<any> {
    return this.httpClient.post('/api/bp/user_sign', bp);
  }

  public managerSign(bp:any): Observable<any> {
    return this.httpClient.post('/api/bp/manager_sign', bp);
  }

  public unvalidate(bp:any): Observable<any> {
    return this.httpClient.post('/api/bp/unvalidate', bp);
  }

  public getPdf(bp: any, year): Observable<any> {
    return this.httpClient.post('/api/bp/pdf?year=' + year, bp);
  }
}
