import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {BaseService} from './base-http.service';

@Injectable({
  providedIn: 'root'
})
export class UsersService extends BaseService {

  constructor(
    private httpClient: HttpClient,
  ) {
    super();
  }

  public exportCollaborators(view: string, year = null, filter = '', sort_column = '', sort_direction = ''): Observable<any> {
    let httpParams: HttpParams = new HttpParams();
    if(sort_column) {
      httpParams = httpParams.set('sort_column', sort_column).set('sort_direction', sort_direction);
    }
    if(filter) {
      httpParams = httpParams.set('filter', filter);
    }
    if(year) {
      httpParams = httpParams.set('year', year);
    }
    return this.httpClient.get<any>('/api/users/export_collaborators/' + view, {params: httpParams});
  }

  public listManagers(): Observable<any> {
    return this.httpClient.get<any>('/api/users/list_managers');
  }

  public getCollaborators(view: string,type: string, skip: number, limit: number, year = null, filter = '', sort_column = '', sort_direction = '' ): Observable<any> {
    let httpParams: HttpParams = new HttpParams().set('skip', skip.toString()).set('limit', limit.toString()).set('type', type);
    if(sort_column) {
      httpParams = httpParams.set('sort_column', sort_column).set('sort_direction', sort_direction);
    }
    if(filter) {
      httpParams = httpParams.set('filter', filter);
    }
    if(year) {
      httpParams = httpParams.set('year', year);
    }
    return this.httpClient.get<any>('/api/users/collaborators/' + view, {params: httpParams});
  }

  public getMe(): Observable<any> {
    return this.httpClient.get<any>('/api/users/me');
  }

  public getStatistics(year = null): Observable<any> {
    let httpParams: HttpParams = new HttpParams();
    if(year) {
      httpParams = httpParams.set('year', year);
    }
    return this.httpClient.get<any>('/api/users/statistics', {params: httpParams});
  }

  public hasManagerActions(year = null): Observable<any> {
    let httpParams = new HttpParams()
    if(year) {
      httpParams = httpParams.set('year', year);
    }
    return this.httpClient.get<any>('/api/users/has_manager_actions', {params: httpParams});
  }
}
