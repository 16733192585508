import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {BaseService} from './base-http.service';

@Injectable({
  providedIn: 'root'
})
export class BrandsService extends BaseService {

  constructor(
    private httpClient: HttpClient
  ) {
    super();
  }

  public list(): Observable<any> {
    return this.httpClient.get<any>('/api/brands/list' );
  }
}
