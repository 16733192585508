import {Component, Input, NgModule, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatSelectModule} from '@angular/material/select';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import {FormControl, ReactiveFormsModule} from '@angular/forms';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {MatFormFieldAppearance} from '@angular/material/form-field/form-field';

@Component({
  selector: 'app-select-multi',
  templateUrl: './select-multi.component.html',
  styleUrls: ['./select-multi.component.scss']
})
export class SelectMultiComponent implements OnInit, OnChanges, OnDestroy {

  private $onDestroy = new Subject<void>();

  // @ts-ignore
  @Input() elements: any[];
  // @ts-ignore
  @Input() valueKey: string;
  // @ts-ignore
  @Input() textKey: string;
  @Input() matFormFieldAppearance: MatFormFieldAppearance = 'fill';
  // @ts-ignore
  @Input() matLabel: string;
  // @ts-ignore
  @Input() matSelectControl: FormControl;
  @Input() matSelectDisabled = false;
  @Input() searchFieldPlaceholder = 'Search';
  @Input() emptySelectPlaceholder = 'Empty';

  public searchControl = new FormControl();
  public displayedElements: any[] = [];

  constructor() {
  }

  ngOnInit(): void {
    if (this.elements) {
      this.displayedElements = Array.from(this.elements);
    }

    this.searchControl.valueChanges.pipe(
      takeUntil(this.$onDestroy)
    ).subscribe(() => {
      this.filterElements();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.elements?.currentValue) {
      this.displayedElements = Array.from(changes.elements.currentValue);
    }
  }

  ngOnDestroy(): void {
    this.$onDestroy.next();
    this.$onDestroy.complete();
  }

  private filterElements(): void {
    if (this.elements) {
      let search = this.searchControl.value;

      if (!search) {
        this.displayedElements = Array.from(this.elements);
        return;
      } else {
        search = search.toLowerCase();
      }

      this.displayedElements = this.elements.filter(element => this.getText(element).toLowerCase().indexOf(search) > -1);
    }
  }

  public toggleSelectAll(selectAllValue: boolean): void {
    if (selectAllValue) {
      this.matSelectControl.patchValue(this.displayedElements.map(e => this.getValue(e)));
    } else {
      this.matSelectControl.patchValue([]);
    }
  }

  public getValue(element: any): any {
    if (this.valueKey && element[this.valueKey]) {
      return element[this.valueKey];
    } else {
      return element;
    }
  }

  public getText(element: any): string {
    if (this.textKey && element[this.textKey]) {
      return element[this.textKey];
    } else {
      return element.toString();
    }
  }
}

@NgModule({
  imports: [CommonModule, MatSelectModule, NgxMatSelectSearchModule, ReactiveFormsModule],
  declarations: [SelectMultiComponent],
  exports: [SelectMultiComponent]
})
export class SelectMultiModule {
}

