<mat-form-field [appearance]="matFormFieldAppearance">
  <mat-label *ngIf="matLabel">{{matLabel}}</mat-label>
  <mat-select [formControl]="matSelectControl" [multiple]="true" [disabled]="matSelectDisabled">
    <mat-option>
      <ngx-mat-select-search
        [placeholderLabel]="searchFieldPlaceholder"
        [noEntriesFoundLabel]="emptySelectPlaceholder"
        [showToggleAllCheckbox]="true"
        [toggleAllCheckboxIndeterminate]="this.elements && matSelectControl.value && matSelectControl.value.length > 0 && matSelectControl.value.length < this.elements.length"
        [toggleAllCheckboxChecked]="this.elements && matSelectControl.value?.length === this.elements.length"
        (toggleAll)="toggleSelectAll($event)"
        [formControl]="searchControl">
      </ngx-mat-select-search>
    </mat-option>
    <mat-option *ngFor="let element of displayedElements" [value]="getValue(element)">
      {{getText(element) | uppercase}}
    </mat-option>
  </mat-select>
</mat-form-field>
