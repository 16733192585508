import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import localeFr from '@angular/common/locales/fr';
import {NgModule, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HomeComponent} from './components/home/home.component';
import {UnauthorizedComponent} from './components/unauthorized/unauthorized.component';
import {LoaderModule} from './libraries/loader/loader.component';
import {LogoModule} from './libraries/logo/logo.component';
import {MaterialModule} from './libraries/material.module';
import {JwtInterceptor} from './services/jwt-interceptor.service';
import {SelectMultiModule} from './libraries/select-multi/select-multi.component';
import { SpaceComponent } from './components/space/space.component';
import { FaqComponent } from './components/faq/faq.component';
import { EpComponent } from './components/ep/ep.component';
import { BpComponent } from './components/bp/bp.component';
import { CollaboratorsListComponent } from './components/collaborators-list/collaborators-list.component';
import { RhSpaceComponent } from './components/rh-space/rh-space.component';
import { StatisticsComponent } from './components/statistics/statistics.component';
import { SynchroBddComponent } from './components/synchro-bdd/synchro-bdd.component';
import {EPService} from './services/ep.service';
import {BrandsService} from './services/brands.service';
import {BPService} from './services/bp.service';
import {UsersService} from './services/users.service';
import { FieldListComponent } from './components/synchro-bdd/field-list/field-list.component';
import {SkillsService} from './services/skills.service';
import { SwitchUserComponent } from './components/switch-user/switch-user.component';
import {MAT_DATE_LOCALE} from '@angular/material/core';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import {MatDatepickerIntlCroComponent} from './libraries/intl/mat-datepicker-intl-cro.component';
import {MatPaginatorIntlCroComponent} from './libraries/intl/mat-paginator-intl-cro.component';
import './libraries/intl/moment.fr';
import {registerLocaleData} from '@angular/common';
import * as moment from 'moment';
import {MatPaginatorIntl} from '@angular/material/paginator';
import {MatDatepickerIntl} from '@angular/material/datepicker';

moment.locale('fr');

registerLocaleData(localeFr, 'fr');

@NgModule({
  declarations: [
    AppComponent,
    UnauthorizedComponent,
    HomeComponent,
    SpaceComponent,
    FaqComponent,
    EpComponent,
    BpComponent,
    CollaboratorsListComponent,
    RhSpaceComponent,
    StatisticsComponent,
    SynchroBddComponent,
    FieldListComponent,
    SwitchUserComponent,
    BreadcrumbComponent
  ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        ReactiveFormsModule,
        HttpClientModule,
        MaterialModule,
        AppRoutingModule,
        LoaderModule,
        FormsModule,
        LogoModule,
        SelectMultiModule
    ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    },
    {
      provide: MAT_DATE_LOCALE, useValue: 'fr-FR'
    },
    {
      provide: LOCALE_ID, useValue: 'fr-FR'
    },
    {
      provide: MatPaginatorIntl,
      useClass: MatPaginatorIntlCroComponent
    },
    {
      provide: MatDatepickerIntl,
      useClass: MatDatepickerIntlCroComponent
    },
    EPService,
    BPService,
    UsersService,
    BrandsService,
    SkillsService
  ],
  entryComponents: [
    FieldListComponent
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
}
